module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1024,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"김춘식의 짱쎈 블로그","short_name":"김춘식 블로그","start_url":"/","background_color":"#111111","theme_color":"#3184ff","display":"minimal-ui","icon":"static/icons/android-icon-96x96.png","icons":[{"src":"/icons/android-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/icons/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/icons/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/icons/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/icons/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/icons/android-icon-192x192.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"74265939958d6b772c829f56c867d5db"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#3184ff","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-GQB79RV9NP","head":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
