import { ThemeProvider as BaseThemeProvider } from '@emotion/react'
import { FC } from "react";
import { GlobalStyles } from './GlobalStyles';
import { BaseTheme, Color } from "./type";

const Theme: BaseTheme = {
  color: Color
}

export const ThemeProvider: FC = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <BaseThemeProvider theme={Theme}>
        {children}
      </BaseThemeProvider>
    </>
  )
}