/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import { ThemeProvider } from './src/components/ThemeProvider'

export function wrapRootElement({ element }) {
  return <ThemeProvider>{element}</ThemeProvider>
}
